import { Routes, Route, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Login from './Components/Authentication/Login';
import Hello from './Components/Landing';
import ForgotPassword from './Components/Authentication/ForgotPassword';
import OnBoarding from './Components/Onboarding';
import Dashboard from './Components/Dashboard';
import FullScan from './Components/Scan/fullScan';
import QuickScan from './Components/Scan/quickScan';
import Landscape from './Components/Landscape';
import Reports from './Components/Reports';
import Profile from './Components/Profile';
import ChangePassword from './Components/Authentication/ChangePassword';
import PrivateRoute from './Components/ProtectedRoutes/private';
import PublicRoute from './Components/ProtectedRoutes/public';
import SignIn from './Components/Authentication/SignIn';
import './App.css';
import Otp from './Components/Authentication/Otp';
import NewPassword from './Components/Authentication/NewPassword';
import PageNotFound from './Components/PageNotFound';
import Menu from './Components/Dashboard/menu';
import Gallery from './Components/Scan/gallery';
import TpScanLanding from './Components/Landing/tpscanLanding'
import { tpscanName } from './Utils/AppDetails/appDetails';
import BernFlow from './Components/Bearn/bernFlow';
import { getDesktopOrMobile } from './Utils/deviceDetails';
import QrCode from './Components/QrCode';
import Validate from './Components/ProtectedRoutes/dentalOffice';
import ValidateApp from './Components/ProtectedRoutes/appDetails';
import PrivacyPolicy from './Components/PrivacyPolicy';
import Feedback from './Components/Feedback';
import Selfie from './Components/FrontCameraFlow';
import TPScanSelfie from './Components/FrontCameraTpScan';
import Unilever from './Components/Unilever';
import Whatsapp from './Components/Whatsapp';
import ReadPhNo from './Components/Unilever/ReadPhNo/readPhNo';
import Protect from './Components/ProtectedRoutes/protect';
import Ipad from './Components/Unilever/Ipad';
import Pg from './Components/PG';
const App = () => {

  const [isDesktop, setDeviceType] = useState(false)
  const [isLandscape, setLandscape] = useState(window.innerWidth > window.innerHeight)
  const handleOrientation = () => {
    detectDevice()
    setLandscape(window.innerWidth > window.innerHeight)
    document.documentElement.style.setProperty("--browser-offset", `calc(var(--vh, 1vh) * 100 - ${window.innerHeight}px)`);
  }

  const detectDevice = () => {
    const isDesktopWindows = getDesktopOrMobile()
    setDeviceType(isDesktopWindows)
  }

  useEffect(() => {
    window.addEventListener("resize", handleOrientation)
    handleOrientation()
    // window.open()
    return () => {
      window.removeEventListener("resize", handleOrientation)
    }
  }, [])


  return (
    <div className="App">
      {
        isDesktop ?
          <Routes>
            <Route path="/" element={<QrCode />} />
            <Route path="/:dentalOfficeName" element={<QrCode />} />
            <Route path="*" element={<QrCode />} />
          </Routes> :
          isLandscape ? <Landscape /> :
            <Routes>
              <Route exact path="/" element={<Protect />} />
              <Route exact path="/selfie/*" element={<Selfie />} />
              <Route exact path="/wh-at-s-app" element={<Whatsapp />} />
              <Route exact path="/unilever/*" element={<Unilever />} />
              <Route exact path="/tpscan/:tpscanName/*" element={<TPScanSelfie />} />
              <Route exact path="/tpscan/:tpscanName" element={<TpScanLanding />} />
              <Route path="/tpscan/bearn" element={<BernFlow />} />
              <Route path='/tpscan/benepower/report' element={<BernFlow />} />
              <Route path="/:ph_no" element={<ReadPhNo />} />
              <Route path="/ipad" element={<Ipad />} />
              <Route path="/pg/*" element={<Pg />} />

              <Route path="*" element={<PageNotFound />} />
              {/* Except tpscan/:tpscanName we are automating the uid's */}
            </Routes>
      }
    </div >
  );
}

export default App;