import React, { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"

const ReadPhNo = () => {

    const navigate = useNavigate()
    const { ph_no } = useParams()
    localStorage.setItem("companyBucket", JSON.stringify({ companyBucket: "unilever" }));

    useEffect(() => {
        const uid = Math.random().toString(36).substring(2, 8)
        const id = `${ph_no}_${uid}`
        localStorage.removeItem("isScanCompleted")
        localStorage.setItem("usertpi", JSON.stringify({ usertpi: id }));
        navigate("/unilever", { replace: true })

    }, [])

    return (
        <div className="h-full flex justify-center items-center">
            <div className="loader-container">
                <div className="loader dark-loader"></div>
            </div>
        </div>
    )
}
export default ReadPhNo