import { useNavigate } from "react-router-dom";
import welcomeTeeth from "../../../assets/illustrations/welcomeTeeth.png";
import toothlensLogo from "../../../assets/illustrations/Toothlens_logo.png";
import pepsodent_logo from "../../../assets/unilever/Pepsodent Logo.png"
import ".././index.css"

const LookingFor = () => {
    const navigate = useNavigate()
    const options = ["anti_cavity", "sensitive", "bad_breath", "yellow_teeth", "others"]
    const optionsTranslated = ["Gigi berlubang", "Gigi ngilu", "Bau mulut", "Gigi kuning", "Lainnya"]

    const handleSelection = (value) => {
        const selected = value === "others" ? "anti_cavity" : value
        localStorage.setItem("template", selected)
        localStorage.getItem("isUnileverIpad") ? navigate("/unilever/single-scan") : navigate("/unilever/scan")

    }

    return (
        <div className="h-full flex flex-col justify-evenly">
            <div className=" flex flex-col items-center justify-center">
                <img
                    src={welcomeTeeth}
                    alt="welcome teeth"
                    className="saturation w-2/5"
                />
                <img
                    src={pepsodent_logo}
                    alt="logo"
                    className=" w-3/4"
                />
            </div>
            <p className="mx-6 text-center text-xl font-medium md:text-3xl"> Pilih lagi permasalahan gigimu </p>
            <div className="flex flex-col px-6 items-center">
                {
                    options.map((option, i) => {
                        return (
                            <button
                                key={i}
                                type="button"
                                className="bg-red-unilever text-white py-2 rounded-lg my-2 md:text-xl  md:py-3 w-full md:w-2/3"
                                onClick={() => { handleSelection(option) }}
                            >
                                {optionsTranslated[i]}
                            </button>
                        )
                    })
                }
            </div>
            <div className="w-full px-4 my-5">
                <p className="flex items-center justify-center md:text-xl">
                    <span className="opacity-60"> Powered by </span>
                    <img
                        src={toothlensLogo}
                        alt="welcome teeth"
                        className=" ml-2 w-24 md:w-32"
                    />
                </p>
            </div>
        </div>
    )
}

export default LookingFor